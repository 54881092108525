export const routes = {
  index: '/',
  login: '/login',
  health: '/health',
  admin: {
    _base: '/admin',
    routeMap: {
      _base: 'route-map',
      id: {
        _base: ':routeMapId',
        settings: 'settings',
        runs: 'runs',
      },
    },
  },
  run: {
    _base: '/run',
    view: ':runId',
  },
  terms: '/terms',
  privacy: '/privacy',
}
