/**
 * Adapted from yuzu411/is-ulid.
 * See also: https://github.com/yuzu441/is-ulid/blob/master/src/IsULID.ts
 */

import { buildMessage, ValidateBy, ValidationOptions } from 'class-validator'

const ULID_REGEX = /^[0-9a-hjkmnp-tv-z]{26}$/

export const isULID = (value: unknown): boolean =>
  typeof value === 'string' && ULID_REGEX.test(value)

export const IsULID = (
  validationOptions?: ValidationOptions,
): PropertyDecorator => {
  return ValidateBy(
    {
      name: 'isULID',
      validator: {
        validate: (value: unknown): boolean => isULID(value),
        defaultMessage: buildMessage(
          (eachPrefix) => eachPrefix + '$property must be an ULID',
          validationOptions,
        ),
      },
    },
    validationOptions,
  )
}
