import { useForm } from 'react-hook-form'
import { FormControl, Link, Stack } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from '@opengovsg/design-system-react'
import { z } from 'zod'

import { isGovSgEmail } from '~shared/decorators'

import { useIsDesktop } from '~hooks/useIsDesktop'

const schema = z.object({
  email: z
    .string()
    .trim()
    .min(1, 'Please enter an email address.')
    .email({ message: 'Please enter a valid email address.' })
    .refine(isGovSgEmail, {
      message: 'Please sign in with a gov.sg email address.',
    }),
})

export type LoginFormInputs = {
  email: string
}
interface LoginFormProps {
  onSubmit: (inputs: LoginFormInputs) => Promise<void>
}

export const LoginForm = ({ onSubmit }: LoginFormProps): JSX.Element => {
  const toast = useToast({
    status: 'error',
    isClosable: true,
  })
  const onSubmitForm = async (inputs: LoginFormInputs) => {
    return onSubmit(inputs).catch((e) => {
      let message = ''
      switch (e.json.statusCode) {
        case 404: {
          toast({
            description: 'Checkpoint no longer accepts new user registrations',
          })
          break
        }
        case 500: {
          message = `We are unable to send emails to ${inputs.email} at this time. Please contact us at https://go.gov.sg/checkpoint-support for assistance.`
          break
        }
        default: {
          message = e.json.message
          break
        }
      }
      if (message) setError('email', { type: 'server', message })
    })
  }

  const { handleSubmit, register, formState, setError } =
    useForm<LoginFormInputs>({
      resolver: zodResolver(schema),
    })

  const isDesktop = useIsDesktop()

  return (
    <form noValidate onSubmit={handleSubmit(onSubmitForm)}>
      <FormControl
        isRequired
        isInvalid={!!formState.errors.email}
        isReadOnly={formState.isSubmitting}
        mb="2.5rem"
      >
        <FormLabel htmlFor="email" fontSize={{ base: '1.125rem', lg: '1rem' }}>
          Log in with a .gov.sg or other whitelisted email address
        </FormLabel>
        <Input
          autoComplete="email"
          autoFocus
          placeholder="e.g. user@agency.gov.sg"
          {...register('email')}
        />
        <FormErrorMessage>{formState.errors.email?.message}</FormErrorMessage>
      </FormControl>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={{ base: '1.5rem', lg: '2.5rem' }}
        align="center"
      >
        <Button
          isFullWidth={!isDesktop}
          isLoading={formState.isSubmitting}
          type="submit"
        >
          Sign in
        </Button>
        <Link href="https://guide.checkpoint.gov.sg/">
          Learn more about Checkpoint
        </Link>
      </Stack>
    </form>
  )
}
