import { Link as ReactLink, useNavigate } from 'react-router-dom'
import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { Button, Link, useIsMobile } from '@opengovsg/design-system-react'

import { AppFooter } from '~/app/AppFooter'

import { useAuth } from '~lib/auth'
import { routes } from '~constants/routes'

export const NotFoundPage = () => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth()

  return (
    <>
      <Flex flex={1} flexDir="column" h="100vh">
        <Flex
          justify="center"
          align="center"
          flexDir="column"
          flex={1}
          bg="base.canvas.brand-subtle"
          py="3rem"
          px="1.5rem"
        >
          {/* <NotFoundSvgr
            maxW="100%"
            mb={{ base: '1.5rem', md: '3rem' }}
            maxH={{ base: '220px', md: 'initial' }}
          /> */}
          <Stack
            spacing="2.5rem"
            color="secondary.500"
            align="center"
            textAlign="center"
          >
            <Text as="h2" textStyle="h2">
              This page could not be found.
            </Text>
            <Stack
              align="center"
              direction={{ base: 'column', md: 'row' }}
              w="100%"
              justify="center"
            >
              <Button isFullWidth={isMobile} onClick={() => navigate(-1)}>
                Back
              </Button>
              {isAuthenticated ? (
                <Link as={ReactLink} to={`/${routes.admin._base}`}>
                  Go to dashboard
                </Link>
              ) : null}
            </Stack>
          </Stack>
        </Flex>
        <Box py={{ lg: '3rem' }} px={{ lg: '9.25rem' }} bg="primary.100">
          <AppFooter variant="compact" />
        </Box>
      </Flex>
    </>
  )
}
