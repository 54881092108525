import {
  buildMessage,
  isEmail,
  ValidateBy,
  ValidationOptions,
} from 'class-validator'

// To whitelist a domain without subdomain, use @<domain> e.g. @open.gov.sg
// To whitelist a domain and its subdomains, use .<domain> e.g. .open.gov.sg
// To whitelist a email, use the full email, e.g. checkpoint@open.gov.sg
const WHITELISTED_DOMAINS_AND_EMAILS = [
  '.gov.sg',

  /*** HEALTHCARE DOMAINS ***/
  /* NUHS */ '@nuhs.edu.sg',
  /* SingHealth */ '@singhealth.com.sg',
  /* NHG */ '@nhg.com.sg',
  /* CGH */ '@cgh.com.sg',
  /* KTPH */ '@ktph.com.sg',
  /* IMH */ '@imh.com.sg',
  /* KKH */ '@kkh.com.sg',
  /* SKH */ '@skh.com.sg',
  /* SGH */ '@sgh.com.sg',
  /* TTSH */ '@ttsh.com.sg',
  /* WHC */ '@whc.sg',
  /* YCH */ '@yishunhospital.com.sg',
  /* NHG Polyclinics */ '@nhgp.com.sg',
  /* MOHH */ '@mohh.com.sg',
  /* MOHT */ '@moht.com.sg',
  /* NSC */ '@nsc.com.sg',
  /* SNEC */ '@snec.com.sg',
  /* SCBB */ '@scbb.com.sg',
  /* NCCS */ '@nccs.com.sg',
  /* NHCS */ '@nhcs.com.sg',
  /* NNI */ '@nni.com.sg',
  /* NDCS */ '@ndcs.com.sg',
  /* AIC */ '@aic.sg',
  /* NCID */ '@ncid.sg',
  /* 1FSS */ '@1fss.com.sg',
  /* ALPS */ '@alpshealthcare.com.sg',
  /* CRIS */ '@cris.sg',
  /* SCRI */ '@scri.edu.sg',
  /* NHIC */ '@nhic.cris.sg',
  /* ACTRIS */ '@actris.cris.sg',
  /* PRECISE */ '@precise.cris.sg',
  /* STCC */ '@stcc.cris.sg',
  /* Synapxe */ '@synapxe.sg',
  /* NHG Diagnostics */ '@diagnostics.nhg.com.sg',
  /* NHG Pharmacy */ '@pharmacy.nhg.com.sg',
  /* NHGP Primary Care Academy */ '@pca.sg',
  /* WHC 2.0 */ '@wh.com.sg',

  /*** SCHOOLS DOMAINS ***/
  /* Ngee Ann Polytechnic */ '@np.edu.sg',
  /* Republic Polytechnic */ '@rp.edu.sg',
  /* Institute of Technical Education*/ '@ite.edu.sg',
  /* Singapore Polytechnic*/ '@sp.edu.sg',
  /* Temasek Polytechnic */ '@tp.edu.sg',
  /* Nanyang Polytechnic */ '@nyp.edu.sg',
]

export const isGovSgEmail = (value: unknown): boolean => {
  if (typeof value !== 'string') return false
  const email = value.toString()
  if (!isEmail(email)) return false
  return WHITELISTED_DOMAINS_AND_EMAILS.some((whitelisted) =>
    isEmail(whitelisted) ? whitelisted === email : email.endsWith(whitelisted),
  )
}

export const IsGovSgEmail = (
  validationOptions?: ValidationOptions,
): PropertyDecorator => {
  return ValidateBy(
    {
      name: 'isGovSgEmail',
      validator: {
        validate: (value: unknown): boolean => isGovSgEmail(value),
        defaultMessage: buildMessage(
          (eachPrefix) =>
            eachPrefix +
            '$property must be a whitelisted Singapore government email',
          validationOptions,
        ),
      },
    },
    validationOptions,
  )
}
