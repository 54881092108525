import { PropsWithChildren, Suspense } from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { Box } from '@chakra-ui/react'

import { routes } from '~constants/routes'
import { lazyImport } from '~utils/lazyImport'

import { NotFoundPage } from '~pages/NotFoundPage'

import { ParamIdValidator } from './ParamIdValidator'

const { AuthRoutes } = lazyImport(() => import('~features/auth'), 'AuthRoutes')
const { TermsOfUseRoutes } = lazyImport(
  () => import('~features/terms'),
  'TermsOfUseRoutes',
)
const { PrivacyPolicyRoutes } = lazyImport(
  () => import('~features/privacy'),
  'PrivacyPolicyRoutes',
)
const { DashboardRoutes } = lazyImport(
  () => import('~features/admin/dashboard'),
  'DashboardRoutes',
)
const { RunRoutes } = lazyImport(() => import('~features/run'), 'RunRoutes')

const { RouteMapRoutes } = lazyImport(
  () => import('~features/admin/route-map'),
  'RouteMapRoutes',
)
const { BuilderRoutes } = lazyImport(
  () => import('~features/admin/route-map/builder'),
  'BuilderRoutes',
)
const { SettingsRoutes } = lazyImport(
  () => import('~features/admin/route-map/settings'),
  'SettingsRoutes',
)
const { RunsRoutes } = lazyImport(
  () => import('~features/admin/route-map/runs'),
  'RunsRoutes',
)

const { HealthRoutes } = lazyImport(
  () => import('~features/health'),
  'HealthRoutes',
)

const router = createBrowserRouter([
  {
    path: routes.index,
    // If index, require login and then redirect to dashboard.
    element: (
      <Navigate to={`${routes.admin._base}/${routes.admin.routeMap._base}`} />
    ),
  },
  {
    path: routes.login,
    element: <AuthRoutes />,
  },
  {
    path: routes.health,
    element: <HealthRoutes />,
  },
  {
    path: routes.admin._base,
    children: [
      {
        path: routes.admin.routeMap._base,
        children: [
          { path: '', element: <DashboardRoutes /> },
          {
            path: routes.admin.routeMap.id._base,
            element: <ParamIdValidator element={<RouteMapRoutes />} />,
            children: [
              {
                path: '',
                element: <ParamIdValidator element={<BuilderRoutes />} />,
              },
              {
                path: routes.admin.routeMap.id.settings,
                element: <ParamIdValidator element={<SettingsRoutes />} />,
              },
              {
                path: routes.admin.routeMap.id.runs,
                element: <ParamIdValidator element={<RunsRoutes />} />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: routes.run._base,
    children: [
      {
        path: routes.run.view,
        element: <ParamIdValidator element={<RunRoutes />} />,
      },
    ],
  },
  {
    path: routes.terms,
    element: <TermsOfUseRoutes />,
  },
  {
    path: routes.privacy,
    element: <PrivacyPolicyRoutes />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
])

const WithSuspense = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<Box bg="neutral.100" minH="$100vh" w="100vw" />}>
    {children}
  </Suspense>
)

export const AppRouter = (): JSX.Element => {
  return (
    <WithSuspense>
      <RouterProvider router={router} />
    </WithSuspense>
  )
}
