import { useParams } from 'react-router-dom'

import { isULID } from '~shared/decorators'

import { NotFoundPage } from '~pages/NotFoundPage'

interface ParamIdValidatorProps {
  element: React.ReactElement
}

export const ParamIdValidator = ({ element }: ParamIdValidatorProps) => {
  const { routeMapId, runId } = useParams()

  const isNotULID = (id?: string) => id && !isULID(id)

  // Bootstrap route validation as suggested by React Router Docs:
  // https://reactrouter.com/en/main/start/faq#what-happened-to-regexp-routes-paths
  if (isNotULID(routeMapId) || isNotULID(runId)) return <NotFoundPage />

  return element
}
