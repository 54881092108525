import { ResponsiveValue } from '@chakra-ui/react'
import { RestrictedFooter } from '@opengovsg/design-system-react'

// TODO: Extend from RestrictedFooterProps instead when they are exported by the package in the future.
interface AppFooterProps {
  variant?: ResponsiveValue<'full' | 'compact'>
  colorMode?: 'light' | 'dark'
}

export const AppFooter = (props: AppFooterProps): JSX.Element => {
  return (
    <RestrictedFooter
      appLink={window.location.origin}
      appName="Checkpoint"
      containerProps={{
        px: 0,
        bg: 'transparent',
      }}
      footerLinks={[
        {
          label: 'Terms of use',
          href: '/terms',
        },
        {
          label: 'Privacy',
          href: '/privacy',
        },
        {
          label: 'Report vulnerability',
          href: 'https://go.gov.sg/report-vulnerability',
        },
      ]}
      {...props}
    />
  )
}
