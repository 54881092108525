import wretch from 'wretch'
import QueryStringAddon from 'wretch/addons/queryString'

import { LOCAL_STORAGE_EVENT } from '~constants/events'
import { STORAGE_LOGGED_IN_KEY } from '~constants/localStorage'

/**
 * Default API client pointing to backend.
 * Automatically catches 403 errors and invalidates authentication state.
 */
export const api = wretch('/api/v1')
  .addon(QueryStringAddon)
  .catcher(401, (err) => {
    // Remove logged in state from localStorage
    localStorage.removeItem(STORAGE_LOGGED_IN_KEY)
    // Event to let useLocalStorage know that key is being deleted.
    window.dispatchEvent(new Event(LOCAL_STORAGE_EVENT))
    throw err
  })
  .errorType('json')
